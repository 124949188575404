import React from 'react'
import Product_image from '../assets/images/Product_image.png'

const Packages = () => {
    return (
        <div>
            <img src={Product_image} alt="" className='w-full' />
        </div>
    )
}

export default Packages